<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search' style='text-align: center'>
          <el-form :inline='true' :model='formData' :rules='rules' ref='formData'>
            <el-form-item label='' prop='title'>
              {{ formData.title }}
              <!--              <el-input placeholder="刊例标题" v-model="formData.title" :clearable="true" :disabled="true" />-->
            </el-form-item>
            <el-form-item label='刊例月份' prop='date_month'>
              <el-date-picker
                :clearable='true'
                :disabled='!!formData.id'
                format='yyyy 年 MM 月'
                placeholder='刊例月份'
                style='width: 140px'
                type='month'
                v-model='formData.date_month'
                value-format='yyyy-MM'>
              </el-date-picker>
            </el-form-item>
            <el-form-item label='有效期' prop='date_range'>
              <el-date-picker
                @change='handlerange'
                end-placeholder='结束日期'
                range-separator='至'
                start-placeholder='开始日期'
                style='width: 240px'
                type='daterange'
                v-model='formData.date_range' value-format='yyyy-MM-dd'>
              </el-date-picker>
            </el-form-item>
            <el-form-item label='提报截止日期' prop='deadline'>
              <el-date-picker
                :clearable='true'
                @change='handldeadline'
                format='yyyy 年 MM 月 dd 日'
                placeholder='提报截止日期'
                type='date'
                v-model='formData.deadline' value-format='yyyy-MM-dd'>
              </el-date-picker>
            </el-form-item>
            <el-form-item style='float: right'>
              <el-link icon='el-icon-d-arrow-left'
                       type='primary'
                       @click='backParentPage'>返回
              </el-link>
            </el-form-item>
          </el-form>

          <div class='default-table' style='padding: 10px;'>
            <el-table :data='dataList' :highlight-current-row='true' :stripe='true'
                      @selection-change='selectionLineChangeHandle'
                      border
                      ref='dataTable' style='width: 100%'>
              <el-table-column
                align='center'
                header-align='center'
                type='selection'
                width='55'>
              </el-table-column>
              <el-table-column align='center' label='平台' width='260'>
                <template slot-scope='{row}'>
                  <div style='display: flex;flex-direction: row'>
                    <div>
                      <el-image :src='row.platform_logo_url'
                                :style='`width:${ImgW};height:${ImgH};`' />
                    </div>
                    <div class='platform_name'
                         style='margin-left: 10px;text-align: center;vertical-align: middle;font-weight: 800'>
                      <span>{{ row.platform_name }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip='true' align='center' header-align='center'
                               label='标题'>
                <template slot-scope='{row}'>
                  <span>{{ row.title }}</span>
                </template>
              </el-table-column>
              <el-table-column align='center' label='有效期' width='200'>
                <template slot-scope='{row}'>
                  <span>{{ row.validity_date }} ~ {{ row.expiry_date }}</span>
                </template>
              </el-table-column>
              <el-table-column align='center' label='提报截止日期' width='180'>
                <template slot-scope='{row}'>
                  <span>{{ row.deadline }}</span>
                </template>
              </el-table-column>
              <el-table-column align='center' label='合作须知' width='160'>
                <template slot-scope='{row}'>
                    <span>
                      <el-checkbox v-model='row.same_notice'>同上月</el-checkbox>
                    </span>
                </template>
              </el-table-column>

            </el-table>

            <div style='margin-top: 8px;text-align: right'>
              <el-button @click='savePublicationMonth' size='medium' type='primary'>保存</el-button>
            </div>
          </div>

          <!--          刊例封面-->

          <el-row class='mb8' v-if='isEdit'>
            <div class='plc_border'>
              <el-row @dblclick.native="editFn('logo_url')">
                <el-col>
                  <img :src='formData.logo_url' alt=''>
                </el-col>
              </el-row>

              <el-row @dblclick.native="editFn('extra_info')">
                <el-col>
                  <div v-html='formData.extra_info'></div>
                </el-col>
              </el-row>
              <el-row @dblclick.native="editFn('company_info')" class='Plc_text'>
                <el-col>
                  <div v-html='formData.company_info'></div>
                </el-col>
              </el-row>
              <el-row class='plc_title'>
                <el-col>
                  资源目录
                  <div style='float: right;'>
                    <el-link icon='el-icon-plus' @click='handleAddResource' style='color: #f2f2f2;font-size: 14px'></el-link>
                  </div>
                </el-col>
              </el-row>
              <div class='resource-dir'>
                <div :key='index' class='sheet-panel' v-for='(item,index) in formData.resource_dir'
                >
                  <div class='flex-sb' style='gap: 0'>
                    <div style='width: 80%' @click="editFn('resource_dir',index)">
                        <img :src='hot' v-show='item.is_hot' style='max-height: 13px;margin-right: 10px' />
                      <span :title='`${item.sheet_title}`'>{{ item.title || '/' }}</span>
                    </div>
                    <div style='line-height: 30px;width: 20%;padding-right: 1px'>
                      <el-link icon='el-icon-close' @click='handleRemoveResource(item,index)'></el-link>
                    </div>
                  </div>

                </div>
              </div>
              <!--              <el-row>-->
              <!--                <el-col :key="index+'i'" :lg="{span:'4-8'}" :md='8'-->
              <!--                        :sm='12'-->
              <!--                        :xs='24'-->
              <!--                        @dblclick.native="editFn('resource_dir',index)"-->
              <!--                        v-for='(item,index) of Math.ceil(formData.resource_dir.length/5)*5'>-->
              <!--                  <img :src='hot' alt='' style='max-height: 13px;margin-right: 10px'-->
              <!--                       v-show='formData.resource_dir[index].is_hot==1?true:false'>-->
              <!--                  {{ formData.resource_dir[index].title }}-->
              <!--                </el-col>-->

              <!--              </el-row>-->

              <el-row class='plc_title'>
                <el-col :span='15'>报价说明
                </el-col>
                <el-col :span='9'>视频合作说明</el-col>
              </el-row>
              <el-row style='text-align: left;line-height: 20px'>
                <el-col :span='15' @dblclick.native="editFn('quote_info')">
                  <div v-html='formData.quote_info'></div>
                </el-col>
                <el-col :span='9' @dblclick.native="editFn('video_info')">
                  <div v-html='formData.video_info'></div>
                </el-col>
              </el-row>
            </div>

            <div class='el-col el-col-22 el-col-offset-1' style='margin-top: 8px;text-align: right'>
              <el-button @click='savePlcdat' size='large' type='danger'>&nbsp;&nbsp;&nbsp;&nbsp;
                保&nbsp;&nbsp;&nbsp;&nbsp;存
                &nbsp;&nbsp;&nbsp;&nbsp;
              </el-button>
            </div>

          </el-row>

        </div>
      </div>
    </PageHeaderLayout>

    <el-drawer
      :direction="editname.title=='resource_dir'||editname.title=='logo_url'?'rtl':'ttb'"
      :visible.sync='drawer'
      :with-header='false'
      size='800px'
      z-index='99'
    >
      <ApeEditor :editorHeight='600' :init-html='formData[editname.title]'
                 :inline='true'
                 @handleTinymceInput='handleTinymceInput'
                 v-if="editname.title!='logo_url'&&editname.title!='resource_dir'"></ApeEditor>
      <div style='padding: 30px' v-if="editname.title=='logo_url'">
        <ApeUploader :limit='1' :upload-file-list='uploadFileList'
                     @handleUploadRemove='handleUploadRemove'
                     @handleUploadSuccess='handleUploadSuccess'></ApeUploader>
      </div>
      <el-form label-width='80px' style='width: 500px;height: 300px;margin: 50px auto;'
               v-if="editname.title=='resource_dir'" v-model='formData.resource_dir '>
        <el-form-item label='名称'>
          <el-input v-model='formData.resource_dir[editname.index].title'>
            {{ formData.resource_dir[editname.index].title }}
          </el-input>
        </el-form-item>
        <el-form-item label='跳转url'>
          <el-input v-model='formData.resource_dir[editname.index].sheet_title'>
            {{ formData.resource_dir[editname.index].sheet_title }}
          </el-input>
        </el-form-item>
        <el-form-item label='热度'>
          <el-switch :active-value='1'
                     v-model='formData.resource_dir[editname.index].is_hot'>
          </el-switch>

        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import hot from '@/assets/icons/hot.png'
import ApeEditor from '@/components/ApeEditor'
import ApeUploader from '@/components/ApeUploader'
import { html2json } from 'html2json'

export default {
  name: 'CreateEdit',
  components: { PageHeaderLayout, ApeEditor, ApeUploader },
  data() {
    return {
      hot: hot,
      ImgW: '40px',
      ImgH: '40px',
      formData: {
        title: '缇苏KOL刊例',
        date_month: null,
        date_range: [],
        deadline: null,
        company_info: '',
        quote_info: '',
        extra_info: '',
        video_info: '',
        resource_dir: [],
        logo_url: ''
      },
      rules: {
        // title: {required: true, message: '公司必填'},
        date_month: { required: true, message: '月份必填' },
        date_range: { required: true, message: '有效期必填' },
        deadline: { required: true, message: '截止日期必填' }
      },
      platforms: [],
      dataList: [],
      drawer: false,
      uploadFileList: [],
      editname: { title: '', index: 0 }
      , isEdit: true,
      selection: []
    }
  },
  watch: {
    'formData.date_month': {
      immediate: false,
      handler(val) {
        if (val && val.indexOf('-') > -1) {
          let arr = val.split('-')
          this.formData.title = `${arr[0]}年${arr[1]}月缇苏KOL刊例`
          if (this.platforms.length > 0 && this.dataList.length === 0) {
            this.defaultDataList()
          }
          //有效期
          if (!this.formData.id) {
            // let date = new Date(arr[0], arr[1] - 1)
            // let lastDate = new Date(arr[0], arr[1], 0)
            this.dataList.forEach((item, index) => {
              this.dataList[index].title = `${arr[0]}年${arr[1]}月缇苏${item.platform_name}KOL刊例`
              // this.dataList[index].validity_date = `${date.getFullYear()}-${date.getMonth() + 1}-01`
              // this.dataList[index].expiry_date = `${lastDate.getFullYear()}-${lastDate.getMonth() + 1}-${lastDate.getDate()}`
            })
          }

          // let deadLineDate = new Date(arr[0], arr[1] - 1)
          // deadLineDate.setMonth(deadLineDate.getMonth() - 1);//默认上个月的月份
          // this.formData.deadline = `${deadLineDate.getFullYear()}-${deadLineDate.getMonth() + 1}-25`
        }
      }
    }

  },
  methods: {

    handlerange(val) {
      if (val && val.length === 2) {
        this.formData.validity_date = val[0]
        this.formData.expiry_date = val[1]
        //有效期
        if (this.dataList) {
          this.dataList.forEach((item, index) => {
            this.dataList[index].validity_date = val[0]
            this.dataList[index].expiry_date = val[1]
          })
        }
      }
    },
    handldeadline(val) {
      if (val && val.indexOf('-') > -1) {
        let arr = val.split('-')
        let date = new Date(arr[0], arr[1] - 1, arr[2])
        this.dataList.forEach((item, index) => {
          this.dataList[index].deadline = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        })
      }
    },
    //
    handleUploadSuccess(file) {
      this.formData.logo_url = file.full_path
    },
    // 图片删除回调
    handleUploadRemove() {
      this.formData.logo_url = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
    },

    //编辑器
    handleTinymceInput(val) {
      this.formData[this.editname.title] = val
    },
    editFn(val, index) {
      this.drawer = true
      this.editname.title = val
      if (index) {
        this.editname.index = index
      }
    },
    handleAddResource(){
      this.formData.resource_dir.push({})
    },
    handleRemoveResource(item, index) {
      this.formData.resource_dir.splice(index, 1)
    },
    async savePlcdat() {
      let postData = {
        year: this.formData.year,
        month: this.formData.month,
        logo_url: this.formData.logo_url,
        company_info: this.formData.company_info,
        quote_info: this.formData.quote_info,
        video_info: this.formData.video_info,
        extra_info: this.formData.extra_info,
        resource_dir: this.formData.resource_dir,
        cover_info_rich: {
          company_info: html2json(this.formData.company_info || ''),
          extra_info: html2json(this.formData.extra_info || ''),
          quote_info: html2json(this.formData.quote_info || ''),
          video_info: html2json(this.formData.video_info || '')
        }
      }
      let id = await this.$api.savePublicationCoverInfo(postData)
      if (id) {
        this.msgSuccess('保存成功')
      }
    },
    defaultDataList() {
      this.platforms.forEach((item) => {
        this.dataList.push({
          platform_code: item.code,
          platform_name: item.name,
          platform_logo_url: item.logo_url,
          title: '缇苏KOL刊例',
          validity_date: null,
          expiry_date: null,
          deadline: null,
          same_notice: true,
          is_check: 0
        })
      })
    },
    async getPlatforms() {
      // 获取最新平台信息
      let searchCondition = { status: 1, is_plc_entry: 1 }
      let { list } = await this.$api.getPlatformAll(searchCondition)
      this.$nextTick(() => {
        this.platforms = list
        // this.defaultDataList()
      })

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      // condition.id =
      return condition
    },
    selectionLineChangeHandle(val) {
      this.selection = val
    },
    async formSubmit() {
      let dateArr = this.formData.date_month.split('-')
      // let t = this
      this.selection = this.selection.map((i) => {
        i.is_check = 1
        return i
      })
      let newData = Array.from(new Set([...this.dataList, ...this.selection]))
      let postData = {
        year: dateArr[0],
        month: dateArr[1],
        title: this.formData.title,
        validity_date: this.formData.validity_date,
        expiry_date: this.formData.expiry_date,
        deadline: this.formData.deadline,
        data: newData
      }
      let id = await this.$api.savePublicationMonthInfo(postData)
      if (id) {
        this.msgSuccess('保存成功')
        await this.$router.push({ name: 'plc_list', params: { year: postData.year, month: postData.month } })
      }
    },
    async savePublicationMonth() {
      // 调用组件的数据验证方法

      this.$refs['formData'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    backParentPage() {
      this.$router.push({ name: 'plc' })
      // this.$router.go(-1)
    }
  },
  async created() {
    let t = this
    // await this.getPlatforms()
    let id = this.$route.params && this.$route.params.id
    if (id) {
      //加载
      let { info, list } = await this.$api.getPublicationMonthDetail(id)
      this.$nextTick(() => {
        // //刊例月份
        info.date_month = `${info.year}-${info.month}`
        // //有效期
        info.date_range = [info.validity_date, info.expiry_date]

        this.formData = info || {}
        this.dataList = list || []
        //
        this.$nextTick(() => {
          list.forEach((i) => {
            if (i.is_check === 1) {
              t.$refs.dataTable.toggleRowSelection(i)
            }
          })
        })
        //如果数组不够10个就凑10个整
        if (!this.formData.resource_dir) {
          // || this.formData.resource_dir?.length < 10
          this.formData.resource_dir = []
          let length = 10 - this.formData.resource_dir.length
          let forLength = { title: '/', is_hot: 0, sheet_title: '/' }
          for (let i = 0; i < length; i++) {
            this.formData.resource_dir.push(forLength)
          }
        }
        this.uploadFileList = [{ url: info.logo_url }]
        //数据默认选中

      })

      this.isEdit = true
    } else {
      await this.getPlatforms()
      let date = new Date()
      date.setMonth(date.getMonth() + 1)//默认下个月的月份
      this.formData.date_month = date.getFullYear() + '-' + (date.getMonth() + 1)
      //
      this.isEdit = false
    }
  }


}
</script>

<style lang='scss' scoped>
$border_color: rgb(0 0 0 / 10%);

.platform_name {
  font-weight: 800;
  font-size: 16px;
  /*border: #00feff dashed 1px;*/
  line-height: 40px;

}

.plc_border {
  //margin-top: 30px;
  border: 1px solid $border_color;
  width: 90%;
  margin: 30px auto;

  > .el-row {
    cursor: pointer;

    > .el-col {
      padding: 10px;
      min-height: 37px;
    }

    text-align: center;
    border-bottom: 1px solid $border_color;

    .el-col-lg-4-8:not(:last-child) {
      border-right: 1px solid $border_color;
      border-bottom: 1px solid $border_color;

      > a {
        color: black;
      }
    }

    @media only screen and (min-width: 768px) {
      .el-col-sm-4-8 {
        width: 20%;
      }
      .el-col-sm-offset-4-8 {
        margin-left: 20%;
      }
      .el-col-sm-pull-4-8 {
        position: relative;
        right: 20%;
      }
      .el-col-sm-push-4-8 {
        position: relative;
        left: 20%;
      }
    }

    @media only screen and (min-width: 992px) {
      .el-col-md-4-8 {
        width: 20%;
      }
      .el-col-md-offset-4-8 {
        margin-left: 20%;
      }
      .el-col-md-pull-4-8 {
        position: relative;
        right: 20%;
      }
      .el-col-md-push-4-8 {
        position: relative;
        left: 20%;
      }
    }

    @media only screen and (min-width: 1200px) {
      .el-col-lg-4-8 {
        width: 20%;
      }
      .el-col-lg-offset-4-8 {
        margin-left: 20%;
      }
      .el-col-lg-pull-4-8 {
        position: relative;
        right: 20%;
      }
      .el-col-lg-push-4-8 {
        position: relative;
        left: 20%;
      }
    }

    @media only screen and (min-width: 1920px) {
      .el-col-xl-4-8 {
        width: 20%;
      }
      .el-col-xl-offset-4-8 {
        margin-left: 20%;
      }
      .el-col-xl-pull-4-8 {
        position: relative;
        right: 20%;
      }
      .el-col-xl-push-4-8 {
        position: relative;
        left: 20%;
      }
    }

    .el-col-15 {
      width: 60%;
      border-right: 1px solid $border_color;
    }

    .el-col-9 {
      width: 40%;
    }
  }

  .Plc_text {
    font-size: 18px;
    /*color: rgb(192, 0, 0);*/
    /*text-decoration: underline;*/
    /*text-align: center;*/
  }

  .plc_title {
    background-color: rgb(192, 0, 0);

    > * {
      color: white;
      text-decoration: underline;
      font-size: 18px;
    }
  }

  > .el-col-md-4-8 {
    border: 1px solid red;
  }
}

.tox {
  z-index: 999999 !important;
  border: 1px solid red
}

.resource-dir {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.resource-dir * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.resource-dir .sheet-panel {
  width: 20%;
  line-height: 30px;
  border: #f2f2f2 1px solid;
  cursor: pointer;
}

</style>
